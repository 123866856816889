import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import {
  type ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'

import { counterAnimation } from '@lib/animate'
import { clampRange } from '@lib/helpers'
import { StringsContext } from '@lib/strings-context'

import Icon from '@components/icon'

interface ProductCounterProps {
  onUpdate: (newQuantity: number, oldQuantity: number) => void
  defaultCount?: number
  max?: number
  isSmall?: boolean
  className?: string
}

const ProductCounter = ({
  onUpdate,
  defaultCount = 1,
  max,
  isSmall,
  className,
}: ProductCounterProps) => {
  const strings = useContext(StringsContext)

  const [direction, setDirection] = useState(1)
  const [motionKey, setMotionKey] = useState('')
  const [isAnimating, setIsAnimating] = useState(false)

  const quantity = useRef(defaultCount)

  const id = useId()

  const animateQuantity = useCallback(
    (newQuantity: number, direction: number) => {
      const newNormalizedQuantity = max
        ? clampRange(newQuantity, 1, max)
        : newQuantity

      // Bail if at edges
      if (newNormalizedQuantity < 1 || (max && newNormalizedQuantity > max)) {
        return
      }

      if (onUpdate) {
        onUpdate(newNormalizedQuantity, quantity.current)
      }

      setIsAnimating(true)
      setDirection(direction)
      setMotionKey(`${newNormalizedQuantity}${direction > 0 ? '-up' : '-down'}`)
      quantity.current = newNormalizedQuantity
    },
    [onUpdate, max]
  )

  const updateQuantity = useCallback(
    (newQuantity: number) => {
      const newNormalizedQuantity = max
        ? clampRange(newQuantity, 1, max)
        : newQuantity

      if (newNormalizedQuantity < 1) {
        return
      }

      if (onUpdate) {
        onUpdate(newNormalizedQuantity, quantity.current)
      }

      setIsAnimating(false)
      quantity.current = newNormalizedQuantity
    },
    [onUpdate, max]
  )

  const incrementQuantity = useCallback(() => {
    animateQuantity(quantity.current + 1, 1)
  }, [animateQuantity])

  const decrementQuantity = useCallback(() => {
    animateQuantity(quantity.current - 1, -1)
  }, [animateQuantity])

  const handleQuantityChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      updateQuantity(parseInt(event.currentTarget.value, 10))
    },
    [updateQuantity]
  )

  const handleQuantityBlur = useCallback(() => {
    if (isNaN(quantity.current)) {
      updateQuantity(1)
    }
  }, [updateQuantity])

  useEffect(() => {
    quantity.current = defaultCount
  }, [defaultCount])

  return (
    <div className={className}>
      <div
        className={cx(
          'border input-border rounded-full inline-grid h-full grid-cols-[auto,auto,auto] text-input-text',
          {
            'p-2': !isSmall,
            'p-1': isSmall,
          }
        )}
      >
        <button
          aria-label={strings.productDecreaseQuantity}
          onClick={decrementQuantity}
          className={cx(
            '-my-px w-10 h-10 inline-flex items-center justify-center text-lg rounded-full transition-colors duration-300 m-0 bg-transparent hover:bg-opacity-5 hover:bg-input-text'
          )}
        >
          <Icon name="Minus" id={`product-counter-${id}`} className="block" />
        </button>
        <div
          className={cx('text-sm relative overflow-hidden', {
            'w-12': !isSmall,
            'w-10': isSmall,
          })}
        >
          <AnimatePresence custom={direction}>
            <motion.div
              key={motionKey}
              initial={isAnimating ? 'hideR' : 'show'}
              animate="show"
              exit="hide"
              variants={counterAnimation}
              custom={direction}
              className="flex w-full h-full will-change-transform absolute inset-0 last:relative last:inset-auto"
            >
              <input
                aria-label={strings.productEnterQuantity}
                onChange={handleQuantityChange}
                onBlur={handleQuantityBlur}
                type="number"
                inputMode="numeric"
                min="1"
                value={quantity.current ? quantity.current : ''}
                className="relative bg-transparent border-0 rounded-none appearance-none p-0 w-full text-center text-current text-sm outline-none no-input-spinners"
              />
            </motion.div>
          </AnimatePresence>
        </div>
        <button
          aria-label={strings.productIncreaseQuantity}
          onClick={incrementQuantity}
          className={cx(
            '-my-px w-10 h-10 inline-flex items-center justify-center text-lg rounded-full transition-colors duration-300 m-0 bg-transparent hover:bg-opacity-5 hover:bg-input-text'
          )}
        >
          <Icon name="Plus" id={`product-counter-${id}`} className="block" />
        </button>
      </div>
    </div>
  )
}

export default ProductCounter
