import cx from 'classnames'
import {
  type ButtonHTMLAttributes,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { type SanityAddToCartButton } from '@data/sanity/queries/types/content'
import { AnalyticsEventName, getProductEventPayload } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { CartContext } from '@lib/cart/context'
import { useAddItemsToCart } from '@lib/cart/hooks'
import {
  getCollectionDiscounts,
  getProductDiscounts,
} from '@lib/discount/product'
import { getPriceWithCurrency } from '@lib/format'
import {
  getDocumentPercentDiscount,
  getProductPrices,
} from '@lib/product/price'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'

import Button, { type ButtonProps } from './button'

type AddToCartButtonProps = ButtonProps &
  Pick<SanityAddToCartButton, 'productVariant'> & {
    onAddToCartError?: () => void
  }

const AddToCartButton = ({
  variant,
  size,
  color,
  icon,
  iconAlignment,
  children,
  className,
  productVariant,
  onAddToCartError,
}: AddToCartButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const { cart } = useContext(CartContext)
  const { currency, currencyCode, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)
  const { user } = useContext(UserContext)

  const addItemsToCart = useAddItemsToCart()

  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [isAddToCartError, setIsAddToCartError] = useState(false)

  const handleAddToCart = useCallback(async () => {
    setIsAddToCartError(false)
    setIsAddingToCart(true)

    const isSuccessful = await addItemsToCart([
      {
        id: productVariant.variantID,
        quantity: 1,
        productId: productVariant.product?._id,
        productCategoryId: productVariant.product?.productCategoryId,
        collectionIds: productVariant.product?.collectionIds,
      },
    ])

    if (!isSuccessful && onAddToCartError) {
      onAddToCartError()
    }

    setIsAddingToCart(false)

    if (isSuccessful) {
      const eventPayload = getProductEventPayload(
        productVariant.product?.productTitle ?? '',
        [
          {
            variantId: productVariant.variantID,
            // TODO: Get variant options for `item_variant`.
            options: [],
            price: productVariant.price,
            comparePrice: productVariant.comparePrice,
            quantity: 1,
          },
        ],
        currencyCode
      )
      triggerAnalyticsEvent(AnalyticsEventName.AddToCart, eventPayload)
    }
  }, [
    addItemsToCart,
    currencyCode,
    onAddToCartError,
    productVariant,
    triggerAnalyticsEvent,
  ])

  const amountWithCurrency = useMemo(() => {
    const productDiscounts = getProductDiscounts(
      user?.company?.productDiscounts
    )
    const collectionDiscounts = getCollectionDiscounts(
      user?.company?.collectionDiscounts
    )
    const productPercentDiscount = getDocumentPercentDiscount(
      productDiscounts ?? [],
      'product',
      productVariant.product?._id ? [productVariant.product._id] : []
    )
    const collectionPercentDiscount = getDocumentPercentDiscount(
      collectionDiscounts ?? [],
      'collection',
      productVariant.product?.collectionIds ?? []
    )

    const [, comparePrice] = getProductPrices({
      variantPrice: productVariant.price,
      productPercentDiscount,
      collectionPercentDiscount,
      companyPercentDiscount: user?.company?.percentDiscount,
    })

    return getPriceWithCurrency(
      comparePrice ?? productVariant.price,
      currency,
      {
        taxRate,
        thousandSeparator: ',',
      }
    )
  }, [currency, productVariant, taxRate, user])

  const isDisabled =
    !cart || !productVariant.inStock || isAddingToCart || isAddToCartError

  return (
    <span className={cx('inline-flex items-center', className)}>
      <span
        className={cx('font-serif font-medium mr-5', {
          'text-sm': size === 'small',
          'text-base': size === 'normal',
        })}
      >
        {amountWithCurrency}
      </span>

      <Button
        id="product-add-to-cart-button"
        variant={variant}
        size={size}
        color={color}
        icon={icon}
        iconAlignment={iconAlignment}
        onClick={handleAddToCart}
        disabled={isDisabled}
      >
        {!cart && <>{strings.buttonUnavailable}</>}
        {!!cart && !productVariant.inStock && <>{strings.productOutOfStock}</>}
        {!!cart && productVariant.inStock && isAddingToCart && (
          <>{strings.buttonAdding}</>
        )}
        {!!cart &&
          productVariant.inStock &&
          !isAddingToCart &&
          !isAddToCartError && <>{children}</>}
      </Button>
    </span>
  )
}

export default AddToCartButton
