import groq from 'groq'

import { simplePortableTextFragment } from './content'
import { imageFragment } from './image'

const productPhotosFragment = groq`
  galleryPhotos[] {
    forOption,
    photos[] {
      ${imageFragment}
    },
  },
  listingPhotos[],
`

const productVariantFragment = groq`
  sku,
  barcode,
  title,
  price,
  comparePrice,
  inStock,
  lowStock,
  seo,
  options[],
  variantID,
`

export const productFragment = groq`
  _id,
  _type,
  _createdAt,
  combinedListingProductType,
  "combinedListingFrameColor": combinedListingFrameColor->color.hex,
  sku,
  barcode,
  title,
  "productCategoryId": category._ref,
  "collectionIds": *[
    _type == "collection" &&
    ^._id in products[]._ref
  ]._id,
  price,
  comparePrice,
  inStock,
  lowStock,
  options[],
  optionNames[],
  slug,
  productID,
  productTitle,
  optionSettings[] {
    forOption,
    "color": color->color {
      hex,
    },
  },
  filters[] {
    forOption,
    "slug": filter->slug,
  },
  description[] {
    ${simplePortableTextFragment}
  },
  "descriptionText": pt::text(description),
  "variants": *[
    _type == "productVariant" &&
    parentId == ^._id &&
    !wasDeleted &&
    !isDraft
  ] {
    ${productVariantFragment}
  },
  automaticGallery,
  ${productPhotosFragment}
`

const combinedListingProductVariantFragment = groq`
  sku,
  price,
  comparePrice,
  variantID,
  options[] {
    position,
    name,
    value,
  },
`

const combinedListingProductFragment = groq`
  _id,
  productID,
  combinedListingProductType,
  "combinedListingFrameColor": combinedListingFrameColor->color.hex,
  combinedListingOptionTitle,
  combinedListingSku,
  combinedListingPercentDiscount,
  combinedListingLargeFormatSettings,
  "productCategoryId": category._ref,
  "collectionIds": *[
    _type == "collection" &&
    ^._id in products[]._ref
  ]._id,
  price,
  comparePrice,
  title,
  slug,
  options[] {
    position,
    name,
    values[],
  },
  "variants": *[
    _type == "productVariant" &&
    parentId == ^._id &&
    !wasDeleted &&
    !isDraft
  ] {
    ${combinedListingProductVariantFragment}
  },
`

const productCombinedListingDefaultsFragment = groq`
  "glassProductIds": defaultGlassProducts[]->productID,
  "backboardProductIds": defaultBackboardProducts[]->productID,
  "passepartoutProductIds": defaultPassepartoutProducts[]->productID,
  "spacerProductIds": defaultSpacerProducts[]->productID,
`

const productCombinedListingFragment = groq`
  _id,
  "frame": frameProducts[]->{
    ${combinedListingProductFragment}
  },
  "glass": glassProducts[]->{
    ${combinedListingProductFragment}
  },
  "backboard": backboardProducts[]->{
    ${combinedListingProductFragment}
  },
  "passepartout": passepartoutProducts[]->{
    ${combinedListingProductFragment}
  },
  "spacer": spacerProducts[]->{
    ${combinedListingProductFragment}
  },
  defaults {
    ${productCombinedListingDefaultsFragment}
  },
`

export const productCombinedListingConfigurationFragment = groq`
  "productCombinedListing": *[
    _type == "productCombinedListing" &&
    locale == $locale &&
    ^._id in frameProducts[]._ref
  ]
    | order(_updatedAt desc)
  [0] {
    ${productCombinedListingFragment}
  },
  "productCombinedListingSettings": *[
    _type == "productCombinedListingSettings" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0] {
    noGlassSku,
    noBackboardSku,
    noPassepartoutSku,
    noSpacerSku,
    defaults {
      ${productCombinedListingDefaultsFragment}
    },
  },
`

export const productsByShopifyIdQuery = groq`
  *[
    _type == "product" &&
    productID == $shopifyProductId
  ] {
    _id,
    title,
  }
`

const productVariantProductFragment = groq`
  _id,
  "productCategoryId": category._ref,
  "collectionIds": *[
    _type == "collection" &&
    ^._id in products[]._ref
  ]._id,
  productID,
  slug,
  title,
  combinedListingProductType,
  combinedListingOptionTitle,
  inheritCartPhotos,
  cartPhotos[],
  ${productPhotosFragment}
`

const productVariantQueryFragment = groq`
  title,
  price,
  options[],
  variantID,
  "product": *[
    _type == "product" &&
    _id == ^.parentId
  ][0] {
    ${productVariantProductFragment}
  },
`

export const productVariantQuery = groq`
  *[
    _type == "productVariant" &&
    variantID == $shopifyProductVariantId &&
    locale == $locale
  ][0] {
    ${productVariantQueryFragment}
  }
`

export const productVariantsQuery = groq`
  *[
    _type == "productVariant" &&
    variantID in $shopifyProductVariantIds &&
    locale == $locale
  ] {
    ${productVariantQueryFragment}
  }
`

export const productVariantsByShopifyIdQuery = groq`
  *[
    _type == "productVariant" &&
    productID == $shopifyProductId
  ] {
    _id,
    variantID,
  }
`

export const productQuery = groq`
  *[
    _type == "product" &&
    _id == $id
  ][0]
  {
    ${productFragment}
  }
`

export const productsQuery = groq`
  *[
    _type == "product" &&
    _id in $ids
  ]
  {
    ${productFragment}
  }
`

export const productAndVariantSearchQuery = groq`
  {
    "products": *[
      _type == "product" &&
      locale == $locale &&
      !wasDeleted &&
      !isDraft &&
      (
        title match $query ||
        pt::text(description) match $query
      )
    ] | score(
      boost(title match $query, 10),
      boost(pt::text(description) match $query, 3),
    ) {
      _id,
      _score,
    }
    [
      _score > 0
    ],
    "productVariants": *[
      _type == "productVariant" &&
      locale == $locale &&
      !wasDeleted &&
      !isDraft &&
      (
        sku match $query
      )
    ] | score(
      boost(sku match $query, 10),
    ) {
      _id,
      _score,
      "productId": parentId,
    }
    [
      _score > 0
    ],
  }
`

export const productResultQuery = groq`
  *[
    _type == "product" &&
    _id in $ids &&
    locale == $locale &&
    !wasDeleted &&
    !isDraft
  ] {
    _id,
    title,
    category->{
      _id,
      title,
      slug,
    },
  }
`

export const combinedListingProductQuery = groq`
  *[
    _type == "product" &&
    combinedListingProductType == "frame" &&
    locale == $locale &&
    productID in $productIds &&
    !wasDeleted &&
    !isDraft
  ]
    | order(_updatedAt desc)
  [0] {
    ${productFragment}
    ${productCombinedListingConfigurationFragment}
  }
`
