import cx from 'classnames'
import { useCallback, useContext, useMemo } from 'react'

import { AnalyticsEventName, getProductEventPayload } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { type LineItem } from '@lib/cart/types'
import {
  getCollectionDiscounts,
  getProductDiscounts,
} from '@lib/discount/product'
import { getProductListingThumbnail } from '@lib/product/images'
import {
  getDocumentPercentDiscount,
  getProductPrices,
} from '@lib/product/price'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'

import ProductCounter from '@blocks/product/product-counter'
import ProductPrice from '@blocks/product/product-price'
import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'

interface CartItemProps {
  lineItem: LineItem
  onUpdateItem: (lineId: string, quantity: number) => void
  onRemoveItem: (lineId: string) => void
  onClose: () => void
  className?: string
}

const CartLineItem = ({
  lineItem,
  onUpdateItem,
  onRemoveItem,
  onClose,
  className,
}: CartItemProps) => {
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const { currencyCode } = useContext(ShopContext)
  const strings = useContext(StringsContext)
  const { user } = useContext(UserContext)

  const thumbnailImage = useMemo(() => {
    if (lineItem.imageUrl) {
      return lineItem.imageUrl
    }

    return getProductListingThumbnail(lineItem.product, lineItem.options)
  }, [lineItem])

  const [, comparePrice] = useMemo(() => {
    const productDiscounts = getProductDiscounts(
      user?.company?.productDiscounts
    )
    const collectionDiscounts = getCollectionDiscounts(
      user?.company?.collectionDiscounts
    )
    const productPercentDiscount = getDocumentPercentDiscount(
      productDiscounts ?? [],
      'product',
      lineItem.product._id ? [lineItem.product._id] : []
    )
    const combinedListingPercentDiscount = getDocumentPercentDiscount(
      productDiscounts ?? [],
      'productCombinedListing',
      lineItem.product._id ? [lineItem.product._id] : []
    )
    const collectionPercentDiscount = getDocumentPercentDiscount(
      collectionDiscounts ?? [],
      'collection',
      lineItem.product.collectionIds ?? []
    )

    return getProductPrices({
      variantPrice: lineItem.price,
      productPercentDiscount:
        productPercentDiscount ?? combinedListingPercentDiscount,
      collectionPercentDiscount,
      companyPercentDiscount: user?.company?.percentDiscount,
    })
  }, [lineItem, user])

  const handleUpdate = useCallback(
    (newQuantity: number, oldQuantity: number) => {
      onUpdateItem(lineItem.lineId, newQuantity)

      if (newQuantity > oldQuantity) {
        const eventPayload = getProductEventPayload(
          lineItem.product.title,
          [
            {
              variantId: lineItem.variantID,
              options: lineItem.options,
              price: lineItem.price ?? 0,
              comparePrice: comparePrice ?? 0,
              quantity: newQuantity - oldQuantity,
            },
          ],
          currencyCode
        )
        triggerAnalyticsEvent(AnalyticsEventName.AddToCart, eventPayload)
      }

      if (newQuantity < oldQuantity) {
        const eventPayload = getProductEventPayload(
          lineItem.product.title,
          [
            {
              variantId: lineItem.variantID,
              options: lineItem.options,
              price: lineItem.price ?? 0,
              comparePrice: comparePrice ?? 0,
              quantity: oldQuantity - newQuantity,
            },
          ],
          currencyCode
        )
        triggerAnalyticsEvent(AnalyticsEventName.RemoveFromCart, eventPayload)
      }
    },
    [comparePrice, currencyCode, lineItem, onUpdateItem, triggerAnalyticsEvent]
  )

  const handleRemove = useCallback(() => {
    onRemoveItem(lineItem.lineId)

    const eventPayload = getProductEventPayload(
      lineItem.product.title,
      [
        {
          variantId: lineItem.variantID,
          options: lineItem.options,
          price: lineItem.price ?? 0,
          comparePrice: comparePrice ?? 0,
          quantity: lineItem.quantity,
        },
      ],
      currencyCode
    )
    triggerAnalyticsEvent(AnalyticsEventName.RemoveFromCart, eventPayload)
  }, [
    comparePrice,
    currencyCode,
    lineItem,
    onRemoveItem,
    triggerAnalyticsEvent,
  ])

  return (
    <div
      className={cx('flex gap-5 sm:gap-8 relative border-b pb-5', className)}
    >
      {!!thumbnailImage && typeof thumbnailImage === 'string' && (
        <figure className="flex-shrink-0 relative m-0 w-1/4 sm:w-1/3 max-w-[10rem] photo">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={thumbnailImage}
            sizes="(min-width: 768px) 400px, 35vw"
            alt={lineItem.product.title}
            className="block overflow-hidden"
          />
        </figure>
      )}

      {!!thumbnailImage && typeof thumbnailImage !== 'string' && (
        <Photo
          image={thumbnailImage}
          sizes="(min-width: 768px) 400px, 35vw"
          className="flex-shrink-0 relative m-0 w-1/4 sm:w-1/3 max-w-[10rem]"
        />
      )}

      <div className="w-full flex flex-col justify-between gap-5">
        <div className="flex justify-between items-start gap-5 gap-x-8">
          <div className="space-y-1">
            {!!lineItem.productUrl && (
              <SimpleLink
                href={lineItem.productUrl}
                onClick={onClose}
                onBeforeInput={onClose}
                tabIndex={0}
                role="link"
              >
                <h4>{lineItem.product.title}</h4>
              </SimpleLink>
            )}
            {!lineItem.productUrl && <h4>{lineItem.product.title}</h4>}

            <p>
              {lineItem.options.map((option, index) => (
                <span key={index}>
                  <span className="font-medium">{option.name}:</span>{' '}
                  {option.value}
                  {index < lineItem.options.length - 1 && ', '}
                </span>
              ))}
            </p>
          </div>

          <ProductPrice
            price={lineItem.price}
            comparePrice={comparePrice}
            showDiscountPercent
            className="hidden xs:inline-flex xs:flex-col xs:justify-end"
          />
        </div>

        <div className="space-y-5">
          <div className="flex justify-between items-center gap-5">
            <ProductCounter
              defaultCount={lineItem.quantity}
              onUpdate={handleUpdate}
              isSmall
            />

            <button
              onClick={handleRemove}
              className="text-sm font-medium hover:opacity-60"
            >
              {strings.buttonRemove}
            </button>
          </div>

          <ProductPrice
            price={lineItem.price}
            comparePrice={comparePrice}
            showDiscountPercent
            className="xs:hidden"
          />
        </div>
      </div>
    </div>
  )
}

export default CartLineItem
